@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium.ttf');
  font-weight: 500;
}

body {
  background-color: transparent !important;
}
